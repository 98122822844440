import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="About" description="What I do and what I have been upto" />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-3/4 xl:w-4/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font  -extrabold tracking-tight text-gray-900 sm:text-4xl">
              About me
            </h1>
            <div className="mt-4 leading-loose">
              I am Pranay Thangeda, a PhD candidate in the Department of Aerospace Engineering 
              at the University of Illinois Urbana-Champaign. I work with Prof. <a href="https://mornik.web.illinois.edu/">Melkior Ornik</a> in the
              Learning, Autonomy, Decision, and Control (LEADCAT) Lab.
              <br />
              <br />
              I’m passionate about intelligent and autonomous systems and my current research interests 
              include the broad areas of optimization, reinforcement learning, resource allocation in large-scale systems, 
              and decision making under uncertainty. More recently, I have been working on developing algorithms for autonomous extra-terrestrial exploration.
              <br />
              <br />
              If you have any questions or something to discuss, you can email me at my personal email{' '} 
              <a
                href="mailto:contact@prny.me"
                className="border-b border-gray-500 hover:border-blue-600 hover:text-blue-600"
              >
                contact@prny.me
              </a>
              {' '}or my Illinois email{' '} 
              <a
                href="mailto:pranayt2@illinois.edu"
                className="border-b border-gray-500 hover:border-blue-600 hover:text-blue-600"
              >
                pranayt2@illinois.edu.
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/4 xl:w-1/5 md:pl-12">
            <Img
              fluid={data.author.childImageSharp.fluid}
              alt="Pranay Thangeda"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "pranayt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
